<template>
  <div class="home">
    <h1>Page not found</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}
</script>
